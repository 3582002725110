// form-accordion-layout.tsx
import React, { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface FormAccordionProps {
    title: React.ReactNode; // Akzeptiert React Nodes
    isLoading: boolean;
    children: React.ReactNode;
}

const FormAccordion: React.FC<FormAccordionProps> = ({ title, isLoading, children }) => {
    // Interner Zustand zur Steuerung der erweiterten Ansicht
    const [expanded, setExpanded] = useState<boolean>(true); // Standardmäßig erweitert

    // Funktion zum Umschalten des erweiterten Zustands
    const handleToggle = () => {
        setExpanded(prev => !prev);
    };

    return (
        <Accordion expanded={expanded} onChange={() => { /* Keine Aktion beim Standard-Toggle */ }}>
            <AccordionSummary
                expandIcon={
                    <ExpandMoreIcon
                        onClick={(e) => {
                            e.stopPropagation(); // Verhindert das Standard-Toggle
                            handleToggle(); // Umschalten des erweiterten Zustands
                        }}
                        style={{ cursor: 'pointer' }} // Ändert den Cursor beim Hover
                    />
                }
                aria-controls="additional-content"
                id="additional-header"
                onClick={(e) => e.stopPropagation()} // Verhindert das Toggling durch Klick auf den gesamten Summary
                sx={{ display: 'flex', alignItems: 'center' }} // Richtige Ausrichtung
            >
                {title} {/* Titel als React Node */}
            </AccordionSummary>
            <AccordionDetails
                sx={{
                    position: 'relative',
                }}
            >
                {/* Ladezustand */}
                <Box
                    sx={{
                        display: isLoading ? 'flex' : 'none',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        zIndex: 2,
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <CircularProgress />
                </Box>
                {children} {/* Inhalt des Accordions */}
            </AccordionDetails>
        </Accordion>
    );
};

export default FormAccordion;
