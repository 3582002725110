// PersonalDetailsForm.tsx
import React, { useEffect, useState } from "react";
import { Grid, Typography, Box, Checkbox, Tooltip, Stack } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'; // Optional für benutzerdefinierte Icons
import { pdfProcessingService } from "../../../../../services/PdfProcessingService";
import { PatientDataMapper } from "../../../../../services/PatientDataMapperService";
import { PersonalDetails } from "../../../../../types/types";
import FormAccordion from "../../../../../layouts/form-accordion-layout";
import MultilineTextField from "../../../../../components/MultilineTextField";

// Utility Type Guard Funktion, um sicherzustellen, dass der Schlüssel ein keyof PersonalDetails ist
const isKeyOfPersonalDetails = (key: string): key is keyof PersonalDetails => {
    return key in PatientDataMapper.generateEmptyPatientPersonalDetails();
};

const PersonalDetailsForm: React.FC = () => {
    // Korrekte Initialisierung des States durch Aufrufen der Funktion
    const [formData, setFormData] = useState<PersonalDetails>(PatientDataMapper.generateEmptyPatientPersonalDetails());
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isFilled, setIsFilled] = useState<boolean>(false);
    const [isTicked, setIsTicked] = useState<boolean>(false);
    const [hasUserModifiedTick, setHasUserModifiedTick] = useState<boolean>(false);

    useEffect(() => {
        const subscription = pdfProcessingService.personalDetailsResult$.subscribe(result => {
            if (result) {
                setFormData(result.personalDetails);
                setIsLoading(result.isLoading);
                setHasUserModifiedTick(false); // Reset user modification flag
                console.log("Received result:", result.personalDetails);
            } else {
                setIsLoading(false);
                const initData = PatientDataMapper.generateEmptyPatientPersonalDetails();
                setFormData(initData);
                setHasUserModifiedTick(false); // Reset user modification flag
                console.log("Initialized formData:", initData);
            }
        });

        // Cleanup when component unmounts
        return () => subscription.unsubscribe();
    }, []);

    useEffect(() => {
        // Alle Felder aus formData extrahieren
        const allFields = Object.values(formData) as { labelName: string; value: string }[];
        const totalFields = allFields.length;

        if (totalFields === 0) {
            setIsFilled(false);
            setIsTicked(false);
            setHasUserModifiedTick(false);
            console.log("Total fields: 0, isFilled set to false");
            return;
        }

        const filledFields = allFields
            .map(field => field.value.trim() !== '' ? 1 : 0)
            .reduce<number>((acc, curr) => acc + curr, 0);

        const filledPercentage = filledFields / totalFields;
        const filled = filledPercentage >= 0.5;

        setIsFilled(filled);
        console.log(`Filled fields: ${filledFields}, Total fields: ${totalFields}, filledPercentage: ${filledPercentage}, isFilled: ${filled}`);

        // Automatisches Setzen von isTicked basierend auf isFilled, es sei denn, der Benutzer hat den Tick manuell geändert
        if (filled && !hasUserModifiedTick) {
            setIsTicked(true);
        }
        else if (!filled && !hasUserModifiedTick) {
            setIsTicked(false);
        }
    }, [formData, hasUserModifiedTick]);

    const handleTickToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newChecked = event.target.checked;
        setIsTicked(newChecked);
        setHasUserModifiedTick(true);
        console.log(`Tick toggled by user: ${newChecked}`);
    };

    const updatePersonalDetailsField = (key: keyof PersonalDetails, newValue: string) => {
        const updatedFormData = { ...formData };
        // @ts-ignore
        updatedFormData[key].value = newValue;
        setFormData(updatedFormData);
    };

    const personalDetailsForm = (
        <Stack>
            <Grid container spacing={2}>
                {Object.entries(formData).map(([key, { labelName, value }]) => {
                    // Sicherstellen, dass der Schlüssel ein gültiger keyof PersonalDetails ist
                    if (!isKeyOfPersonalDetails(key)) {
                        console.warn(`Invalid key: ${key} in PersonalDetails`);
                        return null;
                    }

                    const validKey = key as keyof PersonalDetails;

                    return (
                        <Grid item xs={12} sm={4} key={key}>
                            <MultilineTextField
                                id={`patient-${key}`}
                                label={labelName}
                                value={value}
                                onChange={(e) =>
                                    updatePersonalDetailsField(validKey, e.target.value)
                                }
                            />
                        </Grid>
                    );
                })}
            </Grid>
        </Stack>
    );

    return (
        <Grid item xs={12}>
            <FormAccordion
                title={
                    <Box display="flex" alignItems="center">
                        <Typography variant="h6">Patientendaten</Typography>
                        <Tooltip title="Patientendaten übernehmen">
                            <Checkbox
                                id="personal-details-tickbox"
                                checked={isTicked}
                                onChange={handleTickToggle}
                                color="success"
                                sx={{ ml: 1 }}
                                inputProps={{ 'aria-label': 'completed' }}
                                // Optional: Benutzerdefinierte Icons für Checkbox
                                icon={<RadioButtonUncheckedIcon />} // Icon, wenn nicht angeklickt
                                checkedIcon={<CheckCircleIcon />} // Icon, wenn angeklickt
                            />
                        </Tooltip>
                    </Box>
                }
                isLoading={isLoading}
            >
                {personalDetailsForm}
            </FormAccordion>
        </Grid>
    );
};

export default PersonalDetailsForm;
