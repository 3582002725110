// ReferrerForm.tsx
import React, { useEffect, useState, useCallback } from "react";
import { Box, Grid, Checkbox, Tooltip, Typography, Stack } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'; // Optional für benutzerdefinierte Icons
import { pdfProcessingService } from "../../../../../services/PdfProcessingService";
import { PatientDataMapper } from "../../../../../services/PatientDataMapperService";
import { Referrer } from "../../../../../types/types";
import FormAccordion from "../../../../../layouts/form-accordion-layout";
import MultilineTextField from "../../../../../components/MultilineTextField";

// Utility Type Guard Funktion, um sicherzustellen, dass der Schlüssel ein keyof PersonalDetails ist
const isKeyOfReferrer = (key: string): key is keyof Referrer["personalDetails"] => {
    return key in PatientDataMapper.generateEmptyReferrer().personalDetails;
};

const ReferrerForm: React.FC = () => {
    const [formData, setFormData] = useState<Referrer>(PatientDataMapper.generateEmptyReferrer());
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isFilled, setIsFilled] = useState<boolean>(false);
    const [isTicked, setIsTicked] = useState<boolean>(false);
    const [hasUserModifiedTick, setHasUserModifiedTick] = useState<boolean>(false);

    useEffect(() => {
        const subscription = pdfProcessingService.referrerResult$.subscribe({
            next: (result) => {
                if (result) {
                    setFormData(result.referrer);
                    setIsLoading(result.isLoading);
                    setHasUserModifiedTick(false); // Reset user modification flag
                    console.log("Received referrer data:", result.referrer);
                } else {
                    setIsLoading(false);
                    const initData: Referrer = PatientDataMapper.generateEmptyReferrer();
                    setFormData(initData);
                    setHasUserModifiedTick(false); // Reset user modification flag
                    console.log("Initialized referrer data:", initData);
                }
            },
            error: (err) => {
                setIsLoading(false);
                console.error("Error loading referrer data:", err);
                // Optional: Set an error state to display an error message to the user
            }
        });

        // Cleanup when component unmounts
        return () => subscription.unsubscribe();
    }, []);

    useEffect(() => {
        // Alle Felder aus personalDetails extrahieren
        const allFields = Object.values(formData.personalDetails) as { labelName: string; value: string }[];
        const totalFields = allFields.length;

        if (totalFields === 0) {
            setIsFilled(false);
            setIsTicked(false);
            setHasUserModifiedTick(false);
            console.log("Total referrer fields: 0, isFilled set to false");
            return;
        }

        const filledFields = allFields
            .map(field => field.value.trim() !== '' ? 1 : 0)
            .reduce<number>((acc, curr) => acc + curr, 0);

        const filledPercentage = filledFields / totalFields;
        const filled = filledPercentage >= 0.5;

        setIsFilled(filled);
        console.log(`Filled fields: ${filledFields}, Total fields: ${totalFields}, Filled Percentage: ${filledPercentage}, isFilled: ${filled}`);

        // Automatisches Setzen von isTicked basierend auf isFilled, es sei denn, der Benutzer hat den Tick manuell geändert
        if (filled && !hasUserModifiedTick) {
            setIsTicked(true);
        }
        else if (!filled && !hasUserModifiedTick) {
            setIsTicked(false);
        }
    }, [formData, hasUserModifiedTick]);

    // Verwendung von useCallback für Performance-Optimierung
    const handleTickToggle = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const newChecked = event.target.checked;
        setIsTicked(newChecked);
        setHasUserModifiedTick(true);
        console.log(`Tick toggled by user: ${newChecked}`);
    }, []);

    const updatePersonalDetailsField = useCallback((key: keyof Referrer["personalDetails"], newValue: string) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            personalDetails: {
                ...prevFormData.personalDetails,
                [key]: {
                    ...prevFormData.personalDetails[key],
                    value: newValue,
                },
            },
        }));
    }, []);

    const referrerFormFields = (
        <Stack>
            <Grid container spacing={2}>
                {Object.entries(formData.personalDetails).map(([key, { labelName, value }]) => {
                    // Sicherstellen, dass der Schlüssel ein gültiger keyof Referrer["personalDetails"] ist
                    if (!isKeyOfReferrer(key)) {
                        console.warn(`Invalid key: ${key} in Referrer personalDetails`);
                        return null;
                    }

                    const validKey = key as keyof Referrer["personalDetails"];

                    return (
                        <Grid item xs={12} sm={4} key={key}>
                            <MultilineTextField
                                id={`doc-${key}`}
                                label={labelName}
                                value={value}
                                onChange={(e) =>
                                    updatePersonalDetailsField(validKey, e.target.value)
                                }
                            />
                        </Grid>
                    );
                })}
            </Grid>
        </Stack>
    );

    return (
        <Grid item xs={12}>
            <FormAccordion
                title={
                    <Box display="flex" alignItems="center">
                        <Typography variant="h6">Zuweiser</Typography>
                        <Tooltip title="Zuweiser übernehmen">
                            <Checkbox
                                id="referrer-tickbox"
                                checked={isTicked}
                                onChange={handleTickToggle}
                                color="success"
                                sx={{ ml: 1, borderRadius: '50%' }} // Runde Checkbox
                                inputProps={{ 'aria-label': 'completed' }}
                                // Benutzerdefinierte Icons für eine runde Checkbox
                                icon={<RadioButtonUncheckedIcon />} // Icon, wenn nicht angeklickt
                                checkedIcon={<CheckCircleIcon />} // Icon, wenn angeklickt
                            />
                        </Tooltip>
                    </Box>
                }
                isLoading={isLoading}
            >
                {referrerFormFields}
            </FormAccordion>
        </Grid>
    );

};

export default ReferrerForm;
