// DiagnosisForm.tsx
import React, { useEffect, useState, useCallback } from "react";
import { Box, Divider, Grid, Checkbox, Tooltip, Typography, Stack } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'; // Optional für benutzerdefinierte Icons
import { pdfProcessingService } from "../../../../../services/PdfProcessingService";
import { PatientDataMapper } from "../../../../../services/PatientDataMapperService";
import { Diagnosis } from "../../../../../types/types";
import FormAccordion from "../../../../../layouts/form-accordion-layout";
import MultilineTextField from "../../../../../components/MultilineTextField";

// Utility Type Guard Funktion, um sicherzustellen, dass der Schlüssel ein keyof Diagnosis ist
const isKeyOfDiagnosis = (key: string): key is keyof Diagnosis => {
    return key in PatientDataMapper.generateEmptyDiagnosis();
};

const DiagnosisForm: React.FC = () => {
    // Korrekte Initialisierung des States durch Aufrufen der Funktion
    const [formData, setFormData] = useState<Diagnosis>(PatientDataMapper.generateEmptyDiagnosis());
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isFilled, setIsFilled] = useState<boolean>(false);
    const [isTicked, setIsTicked] = useState<boolean>(false);
    const [hasUserModifiedTick, setHasUserModifiedTick] = useState<boolean>(false);

    useEffect(() => {
        const subscription = pdfProcessingService.diagnosisResult$.subscribe({
            next: (result) => {
                if (result) {
                    setFormData(result.diagnosis);
                    setIsLoading(result.isLoading);
                    setHasUserModifiedTick(false); // Reset user modification flag
                    console.log("Received diagnosis data:", result.diagnosis);
                } else {
                    setIsLoading(false);
                    const initData: Diagnosis = PatientDataMapper.generateEmptyDiagnosis();
                    setFormData(initData);
                    setHasUserModifiedTick(false); // Reset user modification flag
                    console.log("Initialized diagnosis data:", initData);
                }
            },
            error: (err) => {
                setIsLoading(false);
                console.error("Error loading diagnosis data:", err);
                // Optional: Set an error state to display an error message to the user
            }
        });

        // Cleanup when component unmounts
        return () => subscription.unsubscribe();
    }, []);

    useEffect(() => {
        const totalFields =
            formData.acuteDiagnosis.length * 2 +
            formData.chronicDiagnosis.length * 2;

        if (totalFields === 0) {
            setIsFilled(false);
            setIsTicked(false);
            setHasUserModifiedTick(false);
            console.log("Total diagnosis fields: 0, isFilled set to false");
            return;
        }

        const filledFields = [
            ...formData.acuteDiagnosis.map(d => d.name.value.trim() !== '' ? 1 : 0),
            ...formData.acuteDiagnosis.map(d => d.icd10Code.value.trim() !== '' ? 1 : 0),
            ...formData.chronicDiagnosis.map(d => d.name.value.trim() !== '' ? 1 : 0),
            ...formData.chronicDiagnosis.map(d => d.icd10Code.value.trim() !== '' ? 1 : 0),
        ].reduce<number>((acc, curr) => acc + curr, 0);

        const filledPercentage = filledFields / totalFields;
        const filled = filledPercentage >= 0.5;

        setIsFilled(filled);
        console.log(`Filled fields: ${filledFields}, Total fields: ${totalFields}, Filled Percentage: ${filledPercentage}, isFilled: ${filled}`);

        // Automatisches Setzen von isTicked basierend auf isFilled, es sei denn, der Benutzer hat den Tick manuell geändert
        if (filled && !hasUserModifiedTick) {
            setIsTicked(true);
        }
        else if (!filled && !hasUserModifiedTick) {
            setIsTicked(false);
        }
    }, [formData, hasUserModifiedTick]);

    // Verwendung von useCallback für Performance-Optimierung
    const handleTickToggle = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const newChecked = event.target.checked;
        setIsTicked(newChecked);
        setHasUserModifiedTick(true);
        console.log(`Tick toggled by user: ${newChecked}`);
    }, []);

    const updateDiagnosisField = useCallback(
        (
            type: 'acuteDiagnosis' | 'chronicDiagnosis',
            index: number,
            field: 'name' | 'icd10Code',
            newValue: string
        ) => {
            setFormData(prevFormData => ({
                ...prevFormData,
                [type]: prevFormData[type].map((diagnosis, i) => {
                    if (i === index) {
                        return {
                            ...diagnosis,
                            [field]: {
                                ...diagnosis[field],
                                value: newValue,
                            }
                        };
                    }
                    return diagnosis;
                })
            }));
        },
        []
    );

    const formFields = (
        <Stack>
            {/* Acute Diagnoses */}
            <Grid container spacing={2}>
                {formData.acuteDiagnosis.map((acuteDiagnosis, index) => (
                    <React.Fragment key={`acute-${index}`}>
                        <Grid item xs={12} sm={8}>
                            <MultilineTextField
                                id={`acuteDiagnosis-name-${index + 1}`}
                                label={`${acuteDiagnosis.name.labelName} ${index + 1}`}
                                value={acuteDiagnosis.name.value}
                                onChange={(e) => updateDiagnosisField('acuteDiagnosis', index, 'name', e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <MultilineTextField
                                id={`acuteDiagnosis-icd10Code-${index + 1}`}
                                label={`${acuteDiagnosis.icd10Code.labelName} ${index + 1}`}
                                value={acuteDiagnosis.icd10Code.value}
                                onChange={(e) => updateDiagnosisField('acuteDiagnosis', index, 'icd10Code', e.target.value)}
                            />
                        </Grid>
                    </React.Fragment>
                ))}
            </Grid>

            {/* Divider */}
            <Box sx={{ height: 10 }} />
            <Divider />
            <Box sx={{ height: 10 }} />

            {/* Chronic Diagnoses */}
            <Grid container spacing={2}>
                {formData.chronicDiagnosis.map((chronicDiagnosis, index) => (
                    <React.Fragment key={`chronic-${index}`}>
                        <Grid item xs={12} sm={8}>
                            <MultilineTextField
                                id={`chronicDiagnosis-name-${index + 1}`}
                                label={`${chronicDiagnosis.name.labelName} ${index + 1}`}
                                value={chronicDiagnosis.name.value}
                                onChange={(e) => updateDiagnosisField('chronicDiagnosis', index, 'name', e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <MultilineTextField
                                id={`chronicDiagnosis-icd10Code-${index + 1}`}
                                label={`${chronicDiagnosis.icd10Code.labelName} ${index + 1}`}
                                value={chronicDiagnosis.icd10Code.value}
                                onChange={(e) => updateDiagnosisField('chronicDiagnosis', index, 'icd10Code', e.target.value)}
                            />
                        </Grid>
                    </React.Fragment>
                ))}
            </Grid>
        </Stack>
    );

    return (
        <Grid item xs={12}>
            <FormAccordion
                title={
                    <Box display="flex" alignItems="center">
                        <Typography variant="h6">Diagnose</Typography>
                        <Tooltip title="Diagnosen übernehmen">
                            <Checkbox
                                id="diagnose-tickbox"
                                checked={isTicked}
                                onChange={handleTickToggle}
                                color="success"
                                sx={{
                                    ml: 1,
                                    borderRadius: '50%', // Runde Checkbox
                                    '& .MuiSvgIcon-root': {
                                        borderRadius: '50%',
                                    },
                                }}
                                inputProps={{ 'aria-label': 'completed' }}
                                // Benutzerdefinierte Icons für eine runde Checkbox
                                icon={<RadioButtonUncheckedIcon />} // Icon, wenn nicht angeklickt
                                checkedIcon={<CheckCircleIcon />} // Icon, wenn angeklickt
                            />
                        </Tooltip>
                    </Box>
                }
                isLoading={isLoading}
            >
                {formFields}
            </FormAccordion>
        </Grid>
    );

};

export default DiagnosisForm;
